/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-success/some-dos-donts-and-basic-troubleshooting-with-hearing-aids/'], ['en', 'https://www.hear.com/resources/hearing-aid-success/some-dos-donts-and-basic-troubleshooting-with-hearing-aids/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-success/some-dos-donts-and-basic-troubleshooting-with-hearing-aids/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/some-dos-donts-and-basic-troubleshooting-with-hearing-aids/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "some-dos-donts-and-basic-troubleshooting-with-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#some-dos-donts-and-basic-troubleshooting-with-hearing-aids",
    "aria-label": "some dos donts and basic troubleshooting with hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Some Do’s, Don’ts and Basic Troubleshooting with Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So you got yourself a new pair of hearing aids — that’s awesome! Like any other high-tech device, it can take a little time to learn how to operate your hearing aids and properly care for them. Here are a couple of tips to help you get the most out of them for as long as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-donts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-donts",
    "aria-label": "the donts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Don’ts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Avoiding these things will maintain peak performance and extend the life of your hearing aids."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do not get your hearing aids wet! Take them out before a shower, swim or sweaty workout."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do not sleep with them on. They can easily fall out, get tangled in the sheets, or end up in the washing machine."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do not get lotions, hair spray, oils, or creams in or on the hearing aids."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Avoid exposing your hearing aids to excessive temperatures – do not wear them when using a hairdryer or leave them in the car on a hot day."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do not leave your hearing aids lying around – they are easy to misplace and pets love to chew on them (because they smell like the owner)."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do not adjust or repair your hearing aids yourself. If you think something isn’t right, connect with us and we’ll address it."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-dos",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-dos",
    "aria-label": "the dos permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Do’s"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Love your hearing aids and they’ll love you back."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Wear your hearing aids every day based on the recommended wearing schedule"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Insert your hearing aids over a soft area like a bed or by placing a towel on a table in case you drop them."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make it a morning ritual to clean your hearing aids with a brush and dry tissue to remove wax buildup."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "When you take them off, place them in the charging dock to ensure a full charge or leave the battery door open to extend the life of the battery."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Store your hearing aids out of reach from children or pets."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "basic-troubleshooting",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-troubleshooting",
    "aria-label": "basic troubleshooting permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic Troubleshooting"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If they’re not working properly, try the following:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure your hearing aids are on. You can check this by holding it in your closed hand and listening for feedback or whistling."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If your hearing aid uses batteries, you can test the battery by dropping it on a hard surface. The charged battery won’t bounce, but the empty battery will."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "If you have rechargeables, you can check the charge on the charging case or the smartphone app."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure the battery door is completely closed."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Clean the hearing aid. Check the wax guard, dome and receiver to make sure earwax isn’t hurting performance."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure the hearing aid is inserted properly in the ear. Also, make sure you have the right device in the right ear (blue is left and red is right)."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Adjust the volume control (is it turned up too high or too low?)"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "if-you-have-any-questions-please-contact-us",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#if-you-have-any-questions-please-contact-us",
    "aria-label": "if you have any questions please contact us permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "If you have any questions, please contact us."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best thing you can do when you have questions or concerns about your hearing aids is to speak to an expert. Our team is always here to help with anything — even if your dog chewed your hearing aids — so please call us at 888-780-3450."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
